<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Online Görüşme Kimlik Bilgisi</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label class="form-label">Kullanıcı Adı</label>
              <div
                name="oldpassword"
                type="password"
                class="form-control"
              >{{res.username}}</div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <label class="form-label">Parola</label>
            <div class="input-group">
              <div v-if="!isShow" class="form-control">*******************</div>
              <div v-if="isShow" class="form-control">{{res.password}}</div>
              <div v-if="isShow" class="btn btn-white" title="kopyala" @click="copyItem"><i class="fa fa-copy"></i></div>
              <div
              v-if="!isShow"
              @click="showMe"
                name="password"
                type="password"
                class="btn btn-primary"
              >Göster</div>
              <div
              v-if="isShow"
              @click="hideMe"
                name="password"
                type="password"
                class="btn btn-primary"
              >Gizle</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  created() {
    document.title = "Online Görüşme Kimlik Bilgileri";
    this.$store.dispatch("ownerMeetIdInfo").then((value) => {
        this.res=value;
    })
  },
  data(){
    return {
        res:null,
        isShow:false,
    }
  },
  methods: {
    showMe(){
        this.isShow=true;
    },
    hideMe(){
        this.isShow=false;
    },
    copyItem(){
        navigator.clipboard.writeText(this.res.password);
        this.$vToastify.success("Şifre panoya koplayandı", "Başarılı!");
    }
  },
  components: {},
  mounted() {},
};
</script>